<template>
    <div class="add_update_role">
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTabelWidth" append-to-body>
            <div>
                <div class="top_value_box">
                    <el-row>
                        <el-col :span="12">
                            <el-form :model="form" label-width="80px">
                                <el-form-item label="所选车辆">
                                    <el-select v-model="selectedIdsList" multiple placeholder @remove-tag="removeTag" class="select_view">
                                        <el-option disabled v-for="item in selectedList" :key="item.vehicleId" :label="item.vehicleNo" :value="item.vehicleId" class="select_rote_add_update_li">
                                            <span class="box_span">
                                                <span v-text="item.vehicleNo||''"></span>
                                            </span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                </div>
                <jy-query ref="form" :model="form">
                    <jy-query-item prop="vehicleNo" label="车牌号:" :span="1.5">
                        <el-input v-model="form.vehicleNo"></el-input>
                    </jy-query-item>
                    <template slot="rightCol">
                        <el-button type="primary" @click="oncheck">查询</el-button>
                        <el-button type="primary" @click="resetForm('form')">重置</el-button>
                    </template>
                </jy-query>
                <!-- @select-all="selectallchange"@select="selectchange"-->
                <jy-table ref="multipleTable" :data="dataList" v-loading="loading" @selection-change="selectchange">
                    <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                    <jy-table-column type="selection" width="40"></jy-table-column>
                    <jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
                    <jy-table-column prop="vin" label="VIN"></jy-table-column>
                    <jy-table-column prop="routeName" label="所属线路"></jy-table-column>
                    <jy-table-column prop="stationNames" label="关联场站"></jy-table-column>
                    <jy-table-column prop="orgName" label="所属机构"></jy-table-column>
                </jy-table>
                <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelFun">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
    export default {
  data() {
    return {
      type: '',
      isCheck: false,
      title: '车辆选择',
      form: {
        vehicleNo: ''
      },
      dialogVisible: false,
      formLabelWidth: '100px',
      tableSeleList: [],
      selectedList: [],
      selectedIdsList: [],
      pageSize: 10,
      total: 100,
      pageIndex: 1,
      dataList: [],
      loading: true
    }
  },
  activated() {},
  created() {
    // this.getList()
  },
  watch: {
    dialogVisible(newview) {
      if (!newview) {
        // this.resetForm('form')
      }
    },
    tableSeleList: function() {
      //将此操作放到最后所行
      setTimeout(() => {
        this.setSelectedList(this.tableSeleList, 0)
      })
    },
    selectedList: function() {
      this.setSelectedIdsList()
    }
  },
  methods: {
    init(selectedIdsList, selectedList) {
      if (selectedIdsList) {
        this.selectedIdsList = selectedIdsList
      } else {
        this.selectedIdsList = []
      }
      if (selectedList) {
        this.selectedList = selectedList
      } else {
        this.selectedList = []
      }
      this.dialogVisible = true
      this.getList()
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.oncheck()
    },
    cancel() {
      this.dialogVisible = false
      console.log('cancel')
    },
    getList() {
      let option = {
        ...this.form,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }
      console.log(option)
      let url = 'baseinfovehicle/list'
      this.$http.post(url, option).then(
        ({ detail }) => {
          // console.log(detail)
          this.dataList = detail.list
          this.total = detail.total
          this.loading = false
          this.toggleRow()
        },
        () => {
          this.loading = false
        }
      )
    },
    // 查询表格
    oncheck() {
      this.pageIndex = 1
      this.getList()
      console.log('更新')
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.getList()
    },
    // 勾选数据
    selectchange(selection) {
      //   console.log(selection)
      this.tableSeleList = selection
    },
    selectallchange(selection) {
      //   console.log(selection)
      this.tableSeleList = selection
    },
    // 单选
    handleCurrentSelectChange(val) {
      this.dialogVisible = false
      this.$emit('updateSite', val)
    },
    cancelFun() {
      this.dialogVisible = false
    },
    save() {
      console.log(this.selectedList)
      console.log(this.selectedIdsList)
      this.$emit('updateSite', this.selectedIdsList, this.selectedList)
      this.dialogVisible = false
    },
    removeTag(item) {
      this.setSelectedList(this.selectedIdsList, 2)
      this.removeTableSeleItem(item)
    },
    removeTableSeleItem(one) {
      this.tableSeleList.forEach(item => {
        if (item.vehicleId == one) {
          this.$refs.multipleTable.toggleRowSelection(item)
        }
      })
    },
    toggleRow() {
      this.$nextTick(() => {
        // if (this.dataList && this.selectedList) {
        this.dataList.forEach(one => {
          this.selectedList.forEach(item => {
            var inTableSeleList = this.tableSeleList.some(oldOne => {
              return one.vehicleId == oldOne.vehicleId
            })
            if (one.vehicleId == item.vehicleId && !inTableSeleList) {
              one.order = item.order
              item = one
              this.$refs.multipleTable.toggleRowSelection(one)
            }
          })
        })
        // }
      })
    },
    setSelectedList(list, type) {
      var oldList = this.selectedList
      //   console.log(oldList)
      var l = []
      if (type == 0) {
        oldList.forEach(item => {
          var inAll = false,
            inSele = false
          inAll = this.dataList.some(itemAll => {
            return itemAll.vehicleId == item.vehicleId
          })
          inSele = list.some(itemSele => {
            return itemSele.vehicleId == item.vehicleId
          })
          if (!inAll || inSele) {
            l.push(item)
          }
        })
        list.forEach(itemSele => {
          var inOld = oldList.some(itemOld => {
            return itemOld.vehicleId == itemSele.vehicleId
          })
          if (!inOld) {
            l.push(itemSele)
          }
        })
      } else if (type == 1) {
        console.log('1')
      } else if (type == 2) {
        oldList.forEach(item => {
          var inIds = false
          inIds = list.some(one => {
            return one == item.vehicleId
          })
          if (inIds) {
            l.push(item)
          }
        })
      }
      l.forEach((one, k) => {
        one.order = k + 1
      })
      this.selectedList = l
    },
    setSelectedIdsList() {
      //   console.log(this.selectedList)
      //   if (this.selectedList) {
      this.selectedIdsList = this.selectedList.map(item => {
        return item.vehicleId
      })
      //   }
    },
    // 确定选择
    selectSure() {
      if (this.tableSeleList.length === 0) {
        this.$message({
          message: '请选择车牌',
          type: 'warning'
        })
      } else {
        this.dialogVisible = false
        this.$emit('updateSite', this.tableSeleList)
      }
    }
  }
}
</script>
<style type="text/css" lang="scss" scoped="add_update_role">
.top_value_box {
    padding-bottom: 20px;

    .select_view {
        width: 100%;
    }
}

.button_box {
    button {
        float: right;
    }
}

.el-select-dropdown.is-multiple {
    .el-select-dropdown__item {
        &.is-disabled {
            cursor: pointer;

            &:hover {
                background-color: #f5f7fa;
            }
        }

        &.selected.hover {
            &:hover {
                background-color: #f5f7fa;
            }

            background-color: #ffffff;
        }

        &.select_rote_add_update_li {
            background: #ffffff;

            .box_span {
                display: block;

                span {
                    color: rgb(132, 146, 166);
                    font-weight: bold;

                    &:nth-of-type(2) {
                        float: right;
                    }
                }
            }

            &:after {
                display: none;
            }
        }
    }
}

</style>
