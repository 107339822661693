<template>
    <div class="trackReplay">
        <jy-dialog :title="title" :visible.sync="dialogVisible" custom-class="dialog_track" type="table" width="80%">
            <div class="trackReplay_box">
                <jy-query ref="checkForm" :model="diaform">
                    <jy-query-item label="选择车辆" prop="vehicleNo">
                        <el-input placeholder="请选择" v-model="diaform.vehicleNo" disabled></el-input>
                    </jy-query-item>
                    <jy-query-item label="选择设备">
                        <el-select placeholder="请选择" v-model="diaform.deviceId">
                            <el-option v-for="item in deviceList" :key="item.deviceId" :label="deviceTypeList[item.dType].label" :value="item.deviceId"></el-option>
                        </el-select>
                    </jy-query-item>
                    <jy-query-item label="回放时间" prop="vehicleNo" :span="1.6">
                        <el-date-picker
                            v-model="diaform.time"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        ></el-date-picker>
                    </jy-query-item>
                    <template #rightCol>
                        <el-button type="primary" @click="oncheck">查询</el-button>
                        <el-button type="primary" @click="resetForm('dialogForm')">重置</el-button>
                    </template>
                </jy-query>
                <jy-query style="margin-top:10px;">
                    <jy-query-item label="回放速度">
                        <el-select v-model="diaform.multiple" placeholder="请选择">
                            <el-option v-for="item in speedList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </jy-query-item>
                    <template #rightCol>
                        <el-button type="primary" @click="toStart" :disabled="isToStart">回放</el-button>
                        <el-button type="primary" @click="toPause" :disabled="isToPauseStop">暂停</el-button>
                        <el-button type="primary" @click="toStop" :disabled="isToPauseStop">停止</el-button>
                    </template>
                </jy-query>
                <div class="trackReplay_map">
                    <track-replay-map ref="trackReplayMap" :url="url" @haslist="isTackPlay"></track-replay-map>
                </div>
            </div>
        </jy-dialog>
        <depotcar-select ref="carSelectDialog" @updateSite="onCarSite"></depotcar-select>
    </div>
</template>
<script>
    import trackReplayMap from "./trackReplayMap";
    import depotcarSelect from "@/components/pages/admin/resourcesAllocation/depotCar/depotcarSelect";
    import { deviceType } from "@/common/js/wordbook.js";

    export default {
        props: {
            url: {
                type: String,
                default: "/waybillActual/getGpsDataByDevice"
            }
        },
        data() {
            let deviceTypeList = deviceType();
            return {
                isCheck: false,
                title: "轨迹回放",
                canHide: true,
                isHide: false,
                isToStart: true,
                isToPauseStop: true,
                speedList: [
                    {
                        value: 1,
                        label: "1X"
                    },
                    {
                        value: 10,
                        label: "10X"
                    },
                    {
                        value: 30,
                        label: "30X"
                    },
                    {
                        value: 50,
                        label: "50X"
                    },
                    {
                        value: 100,
                        label: "100X"
                    },
                    {
                        value: 300,
                        label: "300X"
                    }
                ],
                batchdeleteList: [],
                deviceTypeList,
                deviceList: [],
                diaform: {
                    vehicleNo: "",
                    vehicleId: "",
                    deviceId: "",
                    multiple: 1,
                    time: [new Date().Format("yyyy-MM-dd ") + "09:37:00", new Date().Format("yyyy-MM-dd ") + "17:37:00"],
                    endTime: "",
                    beginTime: ""
                },
                rules: {
                    vehicleNo: [
                        {
                            required: true,
                            message: "请选择车辆",
                            trigger: ["blur", "change"]
                        }
                    ]
                },
                mapStyle: {},
                pageSize: 10,
                total: 100,
                pageIndex: 1,
                dialogVisible: false
            };
        },
        components: {
            trackReplayMap,
            depotcarSelect
        },
        activated() {},
        created() {},
        watch: {
            dialogVisible(newview) {
                if (!newview) {
                    this.resetForm("dialogForm");
                    this.$refs.trackReplayMap.pageIndex = 1;
                }
            },
            "diaform.vehicleNo": function(val, oldVal) {
                if (val != oldVal) {
                    if (this.diaform.vehicleNo == "") {
                        this.diaform.vehicleId = "";
                    }
                }
            }
        },
        methods: {
            init(option, type) {
                this.dialogVisible = true;
                if (option) {
                    // this.$nextTick(() => {
                    let selectOption = this.$util.deepCopy(option);
                    if (selectOption.vehicleId) {
                        this.diaform.vehicleId = selectOption.vehicleId;
                        this.diaform.vehicleNo = selectOption.vehicleNo;
                        this.diaform.clientId = selectOption.vin;
                    } else if (selectOption.actualVehicleId) {
                        this.diaform.vehicleId = selectOption.actualVehicleId;
                        this.diaform.vehicleNo = selectOption.actualVehicleNo;
                        this.diaform.clientId = selectOption.actualVin;
                    } else if (selectOption.planVehicleId) {
                        this.diaform.vehicleId = selectOption.planVehicleId;
                        this.diaform.vehicleNo = selectOption.planVehicleNo;
                        this.diaform.clientId = selectOption.planVin;
                    }
                    //   时间
                    if (type == "waybill" || type == "workTime") {
                        const curDate = selectOption.oDate || new Date().Format("yyyy-MM-dd");
                        const sTime = selectOption.sTime || selectOption.beginTime;
                        const eTime = selectOption.eTime || selectOption.endTime || "23:59";
                        if (sTime) {
                            let beginTime = curDate + " " + sTime + ":00";
                            this.$set(this.diaform.time, 0, beginTime);
                        }
                        if (eTime) {
                            let endTime = curDate + " " + eTime + ":59";
                            this.$set(this.diaform.time, 1, endTime);
                        }
                    }

                    this.diaform.routeId = selectOption.routeId;

                    this.getDevices();
                }
            },
            getDevices() {
                let url = "/base/device/queryByVehicleId";
                let params = {
                    vehicleId: this.diaform.vehicleId
                };
                this.$http.post(url, params).then(data => {
                    this.deviceList = data.detail;
                    if (data.detail && data.detail.length) {
                        this.diaform.deviceId = data.detail[0].deviceId;
                        this.oncheck();
                    }
                });
            },
            // 查询
            oncheck() {
                // if (!this.diaform.deviceId) {
                //     this.$message({
                //         message:'请选择设备',
                //         type:'warning'
                //     })
                //     return
                // }
                let params = {
                    deviceNo: this.diaform.deviceId,
                    routeId: this.diaform.routeId
                };
                if (this.diaform.time.length) {
                    params.beginTime = this.diaform.time[0];
                    params.endTime = this.diaform.time[1];
                }
                this.$refs.trackReplayMap.getPreTrack(params);
                this.$refs.trackReplayMap.getGpsData(params);
            },
            resetForm(formName) {
                console.log(formName);
                // this.$refs[formName].resetFields()
                this.diaform.time = [new Date().Format("yyyy-MM-dd ") + "09:37:00", new Date().Format("yyyy-MM-dd ") + "17:37:00"];
                this.diaform.multiple = 1;
            },
            cancel() {
                this.dialogVisible = false;
            },
            // 是否可回放
            isTackPlay(option) {
                if (option) {
                    this.isToStart = false;
                    this.isToPauseStop = true;
                } else {
                    this.isToStart = true;
                    this.isToPauseStop = true;
                }
            },
            // 车辆选择
            carSelect() {
                this.$refs.carSelectDialog.init();
            },
            onCarSite(siteArr) {
                // 选择的车辆及对应的VIN显示
                console.log(siteArr);
                siteArr = JSON.parse(JSON.stringify(siteArr));
                this.diaform.vehicleNo = siteArr.vehicleNo;
                this.diaform.vehicleId = siteArr.vehicleId;
            },
            // 开始
            toStart() {
                this.isToStart = true;
                this.isToPauseStop = false;
                this.$refs.trackReplayMap.initStart(this.diaform);
            },
            // 停止
            toStop() {
                this.isToStart = false;
                this.isToPauseStop = true;
                this.$refs.trackReplayMap.initStop();
            },
            // 暂停
            toPause() {
                this.isToStart = false;
                this.isToPauseStop = true;
                this.$refs.trackReplayMap.initPause();
            }
        }
    };
</script>
<style lang="scss" scoped></style>
